import styled from '@emotion/styled';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { IoCloseCircle, IoCloudDownloadSharp } from 'react-icons/io5';
import { APP_IMG } from 'src/constants';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { controller } from 'src/switch-themes';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import Image, { IMAGE_SIZE } from '../cards/image';
type Props = {
  id: string;
  className?: string;
  eventDataLayer?: IDataLayerGlobal;
};
const WidgetNewsPaper = ({ id, className, eventDataLayer }: Props) => {
  if (_.isEmpty(controller?.facebook)) return null;
  const boxRef: any = useRef();
  const [toggleNewsPaper, setToggleNewsPaper] = useState(false);
  return (
    <>
      <WidgetWrapper
        id={id}
        className={className}
        // ! DATALAYER
        onClick={() => {
          setToggleNewsPaper(true);
          sendDataLayerGlobal({
            ...eventDataLayer
          });
        }}
      >
        <Image image={`${APP_IMG}/cache/newspaper_1.webp`} title={'Daily News Paper'} external={true} size={IMAGE_SIZE.MD_WEBP} />
      </WidgetWrapper>
      {toggleNewsPaper && (
        <PopupBox className='popup-box'>
          <div className='image-box' ref={boxRef}>
            <div className='action-box'>
              <a className='btn-download' href='https://www.ookbee.com/Shop/Magazine/THANSETTAKIJ' target='_blank' rel='noreferrer'>
                ดาวน์โหลด <IoCloudDownloadSharp />
              </a>
              <button className='btn-close' onClick={() => setToggleNewsPaper(false)}>
                <IoCloseCircle size={28} color='red' />
              </button>
            </div>
            <Image image={`${APP_IMG}/cache/newspaper_1.webp`} title={'Daily News Paper'} external={true} size={IMAGE_SIZE.MD_WEBP} />
          </div>
        </PopupBox>
      )}
    </>
  );
};

export default WidgetNewsPaper;

const WidgetWrapper = styled.div`
  text-align: center;
  padding: 10px;
  img {
    cursor: pointer;
    width: 100%;
    max-width: 380px;
    margin: auto;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 1s;

    &:hover {
      box-shadow: 2px 4px 10px var(--newspaper-hover);
      transition: all 1s;
    }
  }
`;

const PopupBox = styled.div`
  &.popup-box {
    position: fixed;
    width: 100% !important;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.8);

    .image-box {
      position: relative;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
      top: 50%;
      transform: translate(0, -50%);
      text-align: center;

      img {
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 95vh;
      }

      .action-box {
        position: relative;
        margin: auto;
        width: 100%;
        max-width: 600px;
        .btn-close {
          border: none;
          background: transparent;
          position: absolute;
          top: 5px;
          right: 0px;
        }

        .btn-download {
          display: flex;
          align-items: center;
          position: absolute;
          top: 5px;
          left: 5px;
          background: var(--newspaper);
          color: #fff;
          padding: 3px 10px;
          border-radius: 5px;
          svg {
            margin: 2px 0 0 10px;
          }
        }
      }
    }
  }
`;
